<script setup>
import { inject, useAttrs, computed, defineProps } from "vue";
import XBtn from '@/components/basic/XBtn.vue';
import { useHelpStore } from "@/composition/help/use-help-store";

const props = defineProps({
  help: {
    type: String,
  }
});

const BTNS_COLORS = {
  ID_UNDEFINED: "red",
  NO_CONTENT: "yellow",
  // default color
  HAS_CONTENT: "",
};

const attrs = useAttrs();

const helpId = computed(() => {
  return props.help || attrs["data-help"] || attrs.id;
})

const { helpList, areAllHelpBtnsVisible } = useHelpStore();

const hasHelpContent = computed(() => {
  if (!helpId.value) {
    return "";
  }

  return helpList.value.some((id) => id === helpId.value);
});

const isVisible = computed(() => {
  return hasHelpContent.value || areAllHelpBtnsVisible.value;
});

const btnColor = computed(() => {
  if (!helpId.value) {
    return BTNS_COLORS.ID_UNDEFINED;
  }

  return !hasHelpContent.value ? BTNS_COLORS.NO_CONTENT : BTNS_COLORS.HAS_CONTENT;
})

const { openDialog } = inject("DialogsRoot");

const openHelpDialog = async () => {
  await openDialog("HelpDialog", { helpId: helpId.value }, { isUiDialog: true });
}
</script>

<template>
  <XBtn
    v-if="isVisible"
    :color="btnColor"
    icon="mdi-help-circle"
    @click.stop="() => openHelpDialog()"
  />
</template>


import requests from "@/js/requests";
import { useNotifications } from "@/composition/notifications";

export function useZipFileDownloader() {
  const { showNotification } = useNotifications();

  const _download = (items) => {
    return new Promise((resolve) => {
      requests.frameworkPostRequest(
        "?f=testing&f2=testInfoPdf",
        "getZippedReports",
        { responseType: "ajax", selected: items, },
        resolve
      );
    });
  };

  const downloadZipFile = async (items) => {
    try {
      const result = await _download(items);

      if (!result?.json?.redirect) {
        throw new Error("Failed to generate the report." );
      } 

      const zipFileUrl = result.json.redirect;
      const downloadLink = document.createElement("a");
      downloadLink.href = zipFileUrl;
      downloadLink.download = "";
      downloadLink.click();
    } catch (err) {
      showNotification({ message: "Failed to generate the report." });
      throw err;
    }
  };

  return {
    downloadZipFile,
  };
}


const ID = Math.random().toString(36).substr(2, 9);
const bc = new BroadcastChannel("test-info-admin-info");
const callbacks = [];

bc.onmessage = async (event) => {
  const { data } = event;
  if (data?.id !== ID) {
    await Promise.all([
      callbacks.map((cb) => cb(data.value)),
    ]);
  }
};

export const testInfoAdminInfoChannel = {
  addCallback(cb) {
    callbacks.push(cb);
  },

  notify(value) {
    bc.postMessage({ value, id: ID });
  },

  removeCallback(cb) {
    const idx = callbacks.findIndex((_cb) => _cb === cb);
    if (idx >= 0) {
      callbacks.splice(idx, 1);
    }
  },
};





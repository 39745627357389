<script setup>
import MenuButton from "@/components/specific/menu/MenuButton.vue";
import AdministrationMenu from "@/components/specific/menu/AdministrationMenu.vue";
import TestingMenu from "@/components/specific/menu/TestingMenu.vue";
import ConfigurationMenu from "@/components/specific/menu/ConfigurationMenu.vue";
import { inject } from "vue";
import { useStore } from "@/store";

const IS_AUTH_PROVIDE = "IS_AUTH";

const { isAuth } = inject(IS_AUTH_PROVIDE);

const store = useStore();
</script>

<template>
  <v-app-bar
    class="app-bar"
    color="primary"
  >
    <div class="app-bar__content">
      <div class="app-bar__left-side">
        <img
          class="app-bar__acctopus-logo"
          src="../../../assets/acctopus-logo.svg"
          alt="Acctopus Logo"
        />

        <template v-if="isAuth && store.state.user.id">
          <v-btn
            color="primary"
            elevation="0"
            active-class="app-bar__no-active-btn-link"
            :to="{ name: 'home' }"
          >
            Dashboard
          </v-btn>

          <MenuButton text="Testing">
            <div class="app-bar__menu-padding">
              <TestingMenu />
            </div>
          </MenuButton>

          <MenuButton text="Configuration">
            <ConfigurationMenu />
          </MenuButton>

          <MenuButton text="Administration">
            <div class="app-bar__menu-padding">
              <AdministrationMenu />
            </div>
          </MenuButton>
        </template>
      </div>

      <div class="app-bar__right-side">
        <img
          class="app-bar__degust-logo"
          src="../../../assets/degust-logo.png"
          alt="Degust Logo"
        />
      </div>
    </div>
  </v-app-bar>
</template>

<style lang="scss">
.app-bar {
  z-index: 1;

  &__content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 100%;
  }

  &__left-side {
    display: flex;
    align-items: center;
    height: 100%;
  }

  &__acctopus-logo {
    height: 80%;
    margin-right: 20px;
  }

  &__right-side {
    display: flex;
    align-items: center;
    height: 100%;
  }

  &__degust-logo {
    height: 80%;
  }

  &__menu-padding {
    padding: 15px 35px 10px 35px;
  }

  &__no-active-btn-link.v-btn--active:not(:focus):not(:hover)::before {
    opacity: 0 !important;
  }
}
</style>

import Vue from "vue";
import { helpService } from "@/api";

const CACHE_LIMIT = 10;

const _state = {
  areAllHelpBtnsVisible: false,
  list: [],
  _cache: {},
};

const help = {
  namespaced: true,

  state: () => ({
    ..._state,
  }),

  getters: {
    list: (state) => state.list,
    getFromCache: (state) => (id) => state._cache[id] || "",
    areAllHelpBtnsVisible: (state) => state.areAllHelpBtnsVisible,
  },

  mutations: {
    toggleHelpBtnsVisibility(state) {
      state.areAllHelpBtnsVisible = !state.areAllHelpBtnsVisible;
    },

    setHelpList(state, list) {
      state.list = list;
    },

    cacheHelpText(state, { id, text }) {
      const cacheKeys = Object.keys(state._cache);
      if (cacheKeys.length < CACHE_LIMIT) {
        state._cache[id] = text;
        return;
      }

      Object.keys(state._cache).forEach(key => {
        Vue.delete(state._cache, key);
      });

      state._cache[id] = text;
    },

    resetState(state) {
      Object.keys(state).forEach(key => {
        // eslint-disable-next-line no-undef
        state[key] = structuredClone(_state[key]);
      });
    },
  },

  actions: {
    async fetchHelpTextById({ commit, getters }, { isRefresh = false, id }) {
      const cached = getters["getFromCache"](id);
      if (cached && !isRefresh) {
        return;
      }

     const helpText = helpService.v1.getContentById(id);
      if (helpText) {
        commit("cacheHelpText", { id, text: helpText });
        return helpText;
      }
    },

    async fetchHelpIdsList({ commit, state }, { isRefresh = false } = {}) {
      if (state.list.length && !isRefresh) {
        return;
      }

      const list = await helpService.v1.getIdsList();
      if (list) {
        commit("setHelpList", list)
      }
    },
  }
}

export default help;
